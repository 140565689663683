import { initializeApp } from "firebase/app";
import { getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'

import { getAnalytics } from "firebase/analytics";


// group fireBase Configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBgRvIW4YahLh1t9as_ItRsW_skdjzSw_s",
//   authDomain: "ai-generate-content-167af.firebaseapp.com",
//   projectId: "ai-generate-content-167af",
//   storageBucket: "ai-generate-content-167af.appspot.com",
//   messagingSenderId: "599434954092",
//   appId: "1:599434954092:web:9a4ef579866897f063df49"
// };


//varshini firebase connfiguration
const firebaseConfig = {
  apiKey: "AIzaSyBmg8Vlrn0PouDiLiGvbwdh4bcCgRnYhoQ",
  authDomain: "contentengine-8cdc0.firebaseapp.com",
  projectId: "contentengine-8cdc0",
  storageBucket: "contentengine-8cdc0.appspot.com",
  messagingSenderId: "139062393205",
  appId: "1:139062393205:web:cffe29ef96a94e91cf3b3c",
  measurementId: "G-T86C6CGY5F"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)
const analytics = getAnalytics(app);

