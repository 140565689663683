import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsCategorySelected } from '../../Routes/Slices/dashBoardSlice';

function ListExample() {
  const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLogout =  () => {
        localStorage.removeItem("uid")
        localStorage.removeItem("token")
        dispatch(setIsCategorySelected(false))
        navigate("/")
    };
  return (
    <>
    <div className='container'>
      <Nav className='navContainer' >
        <div className='navLi'>
            <Link to={"/dashboard"} className='linkInNav' >Home</Link>
        </div>
        <div className='navLi'>
            <Link to={"/user/setting"} className='linkInNav' >settings</Link>
          </div>
          <div className='navLi'>
                <Link to={"/template"}className='linkInNav' >Templates</Link>
          </div>
       
        
        
    <div className='navLogout'>
        <button className='btn btn-danger'   onClick={handleLogout}>Logout</button>
    </div>
    </Nav>
    </div>
    </>
  );
}

export default ListExample;